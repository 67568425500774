import React from "react"
import "../styles/fontawesome-free-5.15.4-web/css/all.css"
import logo from "../images/logo-web.svg"

export default function footer() { 
    return(
    <>
    {/*<!--Footer-->*/}
    <footer class="bg-white shadow">
      <div class="container mx-auto">
        <div class="w-full flex flex-row justify-between">
          <div class="pt-4">
            <a class="" href="/">
            <img src ={logo}></img>
            </a>
          </div>
          
    

          <div class="flex-auto">
          </div>

          <div class="flex-auto text-right">
            
          </div>

          <div class="px-4 pt-2">
          <a href="/">
                                  <button class="bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded">
                                  Go Back
                                  </button></a>
          </div>
        </div>
        <div class="w-full flex bg-white text-black text-sm">
          &copy; Candela Marbella&nbsp;
          <a href="https://candelamarbella.com/en/legal-notice/" class="no-underline hover:underline text-gray-500 ">Legal Notice | </a>
        <a href="https://candelamarbella.com/en/privacy-policy/" class="no-underline hover:underline text-gray-500 ">Privacy Policy</a>
        </div>

      </div>
    </footer>
    </>
    )
}